body {
	margin: 0;
	background-color: #f0f0f0;
	color: #444;
	font-family: Monospace;
	font-size: 13px;
	line-height: 24px;
	overscroll-behavior: none;
}

a {
	color: #ff0;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

button {
	cursor: pointer;
	text-transform: uppercase;
}

#divCanvas{
    position: absolute;
    top: 0px;
    width: 100%;
}

#info {
	position: absolute;
	top: 0px;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	text-align: center;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	z-index: 1; /* TODO Solve this in HTML */
}

a, button, input, select {
	pointer-events: auto;
}

.lil-gui {
	z-index: 2 !important; /* TODO Solve this in HTML */
}

@media all and ( max-width: 640px ) {
	.lil-gui.root { 
		right: auto;
		top: auto;
		max-height: 50%;
		max-width: 80%;
		bottom: 0;
		left: 0;
	}
}

#overlay {
	position: absolute;
	font-size: 16px;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: rgba(0,0,0,0.7);
}

	#overlay button {
		background: transparent;
		border: 0;
		border: 1px solid rgb(255, 255, 255);
		border-radius: 4px;
		color: #ffffff;
		padding: 12px 18px;
		text-transform: uppercase;
		cursor: pointer;
	}

#notSupported {
	width: 50%;
	margin: auto;
	background-color: #f00;
	margin-top: 20px;
	padding: 10px;
}

{
    overflow-y: hidden;
}

@media only screen and (min-width: 1366px) and (orientation: landscape) {
    #overlay{

        position:fixed;
        width:39%;
        height:45px;
        bottom:0px;
        left:52%;
        padding: 20px;
        font-size: 1.2em;
        color: rgb(0, 0, 0);
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.1);;

    }
}

@media only screen and (min-width: 1024px) and (orientation: portrait) {
    #overlay{

        position:fixed;
        width:39%;
        height:45px;
        bottom:0px;
        left:52%;
        padding: 20px;
        font-size: 1.2em;
        color: rgb(0, 0, 0);
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.1);;

    }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
    #overlay{

        position:fixed;
        width:39%;
        height:45px;
        bottom:0px;
        left:52%;
        padding: 20px;
        font-size: 1.2em;
        color: rgb(0, 0, 0);
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.1);;

    }

}

@media screen and (max-width: 500px) {
    #overlay{

        position:fixed;
        width:70%;
        height:55px;
        bottom:0px;
        left:20%;
        padding: 20px;
        font-size: 1.2em;
        color: rgb(0, 0, 0);
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.1);;

    }

}
